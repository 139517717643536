import { useCallback } from 'react';
import { useFetcher, useRouteLoaderData } from 'react-router';
import { $path, $routeId } from 'safe-routes';

import type { Route } from '~/types/_auth';

const getOpenFromFetcher = (formData: FormData) => {
  const value = formData.get('open');
  if (typeof value === 'string') {
    return JSON.parse(value) as boolean;
  }

  return false;
};

export const useSidebar = (): [boolean, (open: boolean) => void] => {
  const data = useRouteLoaderData<Route.ComponentProps['loaderData']>($routeId('routes/_auth'));
  const fetcher = useFetcher();

  const open = fetcher.formData ? getOpenFromFetcher(fetcher.formData) : (data?.sidebar ?? true);
  const setOpen = useCallback(
    (open: boolean) => {
      fetcher.submit({ open }, { action: $path('/set-sidebar'), method: 'post' });
    },
    [fetcher]
  );

  return [open, setOpen];
};
